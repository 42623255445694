.icon-enter {
    opacity: 0;
  }
  .icon-enter-active {
    opacity: .1;
    transition: opacity 50ms;
  }

  .icon-exit {
    opacity: .1;
  }
  .icon-exit-active {
    opacity: 1;
    transition: opacity 500ms;
  }


 