.front-enter {
  opacity: 0;
  transform: scale(1.3);
}

.front-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2200ms, transform 2200ms;
}

.front-exit {
  opacity: 1;
}

.front-exit-active {
  opacity: 0;
  transform: scale(.05);
  transition: opacity 500ms, transform 1700ms;
}