.agents-enter {
    opacity: 0;
    transform: scale(1.3);
  }
  .agents-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1200ms, transform 1200ms;
  }
  .agents-exit {
    opacity: 1;
  }
  .agents-exit-active {
    opacity: 0;
    transform: scale(0.3);
    transition: opacity 500ms, transform 700ms;
  }

  .guns-exit {
    opacity: .1;
  }
  .guns-exit-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  .MuiFormControlLabel-label{
    color: #FF4654 !important;
  }

