@media (max-width: 786px) {
  ::-webkit-scrollbar {
    width: 0px !important;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #292626;
    border-radius: 0px !important;
    border: 0px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #292626;
  }

  html, body, body > div {
    overflow: hidden visible !important;
  }
}



html, body, body > div {
  height: 100%;
  background-color: #060D18;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex{
  display:flex;
}

.dir-col{
  flex-direction: column;
}

.jc-start{
  justify-content: start;
}

.ai-center{
  align-items: center;
}

.ai-start{
  align-items: start;
}

.flex-grow{
  flex-grow: 1;
}


.height-87{
  height:87%
}

.height-100{
  height:100%;
}

.margin{
  margin:1%;
}

.margin-bottom{
  margin-bottom: 2%;
}


.text-white{
  color: white;
}

.width-100{
  width:100%;
}

.width-85{
  width:85%
}

.text-color-blue{
  color: #7CA9FF;
}

.justify-content-end{
  justify-content: end;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #292626;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #292626;
}
