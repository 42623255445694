.background{
    min-height: 100%;
    min-width:100%;
      
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
      
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}