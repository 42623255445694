/* Flex */ 
.flex {
    display: flex;
}

.grid{
    display: grid;
}

.grid-row-1{
    grid-row: 1;
}

.grid-col-1{
    grid-column: 1;
}

.dir-col {
    flex-direction: column;
}

.jc-start {
    justify-content: start;
}

.jc-center {
    justify-content: center;
}

.jc-end {
    justify-content: end;
}

.jc-even{
    justify-content: space-evenly;
}

.jc-around{
    justify-content: space-around;
}

.jc-flex-start{
    justify-content: flex-start;
}
.ai-start {
    align-items: start;
}

.ai-center {
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}