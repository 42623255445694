@media (max-width: 786px) {
    .strat-text{
        font-size: x-large !important;
    }
}

.strat-text{
    color: #FF4654;
    font-size: xx-large;
    font-weight: bold;
}

.white-text{
    color: white;
}