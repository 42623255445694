
@media (max-width: 786px) {
    .mobile-hide{
        display:none !important;
    }
    .normal-hide{
        display:flex !important;
    }
}

@media (min-width: 1400px) {
    .scrollable{
        overflow: scroll;
    }
}

.normal-hide{
    display:none;
}

.scrollable{
    overflow: scroll;
}

.text-center{
    text-align: center;
}

/* Height */
.height-inherit{
    height: inherit;
}
.height-87 {
    height: 87%
}

.height-100 {
    height: 100%;
}
.height-90 {
    height: 90%;
}
.width-100 {
    width:100%;
}


/* Margin */
.margin {
    margin: 1%;
}

.margin-bottom {
    margin-bottom: 2%;
}


.text-white {
    color: white;
}

.text-white {
    color: white;
}

.width-100 {
    width: 100%;
}

.width-85 {
    width: 85%
}

.text-color-blue {
    color: #7CA9FF;
}

.main-background{
    background-color: #060D18;
}

.button-bold{
    font-size: large;
    font-weight: bolder;
    text-transform: none;
}
