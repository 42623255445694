.top-nav{
    max-height: max-content;
    width: 100%;
}

.margin-left{
    margin-left:2%;
}

.margin-2{
    margin-top:2%;
    margin-bottom:2%;
}

.margin-left-35{
    margin-left: 35%;
}

.grid-top-nav{
    display: grid;
    grid-template-rows: 1fr;
}

