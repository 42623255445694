

.fade {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.margin-left-right-auto{
    margin-left:auto;
    margin-right: auto;
}

.left-side{
    max-width: max-content;
    height: 100%;
}

.text-commands{
    color: #7CA9FF;
    font-size: 2em;
    white-space: nowrap;
    /* font-weight: bolder; */
}

.margin-commands{
    margin:8%
}

.avatar{
    margin-top:"2em" !important;
}

.question{
    margin-top: 25% !important;
}

.margin-top-half{
    margin-top:"1em" !important;
}

.left-side-margin{
    margin-left:20%;
}

.App-logo {
    height: 18vmin;
    pointer-events: none;
}

.left-bar{
    min-width: 300px;
    margin-right:2%;
    margin-left:1%;
}

.margin-button{
    margin-top:20%;
}





