.weapon-enter {
    opacity: 0;
    transform: scale(412.9);
  }
  .weapon-enter-active {
    opacity: 1;
    transform: translateX(31);
    transition: opacity 300ms, transform 300ms;
  }
  .weapon-exit {
    opacity: 1;
  }
  .weapon-exit-active {
    opacity: 0;
    transform: scale(1.9);
    transition: opacity 300ms, transform 300ms;
  }
  