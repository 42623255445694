@media (max-width: 786px) {
    .main-text{
        color: #7CA9FF;
        font-size: x-large !important;
        font-weight: bolder;
    }

    .main-small{
        margin-bottom: 10%;
        margin-left: 1%;
        margin-right: 1%;
        height: 100%;
    }

    .strat-text{
        max-width: 100% !important;
        min-height: 1%;
        max-height: 1%;
        margin-left: auto;
        margin-right: auto;
    }

    .icon-mobile{
        margin-top: 10%;
    }
}

.margin-top-bot-auto{
    margin-top:auto;
    margin-bottom: auto;
}

.width-90{
    width: 100%;
}


.margin-main{
    margin: 0% 5% 5% 5%;
}

.main-text{
    color: #7CA9FF;
    font-size: xxx-large;
    font-weight: bolder;
}

.margin-left-1{
    margin-left: 1%;
}

.margin-top-5{
    margin-top: 5%
}

.margin-right-5{
    margin-right: 5%;
}

.scrollable{
    overflow: auto;
}

.strat-text{
    max-width: 50%;
    min-height: 1%;
    max-height: 1%;
    margin-left: auto;
    margin-right: auto;
}

.background-light{
    background-color: #0F1922;  
    border-radius: 30px;
}
